import { useField } from "formik";

const CustomInput = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label>
        {label}
        <input
          {...field}
          {...props}
          className={className + (meta.touched && meta.error ? " input-error" : " ")}
        />
        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
      </label>
    </>
  );
};
export default CustomInput;