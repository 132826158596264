import React from 'react';
import '../App.css';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container' >
     
    <h4>MICHAŁ HAJDOWSKI ARCHITEKT KRAJOBRAZU </h4>
      <h4>Projektowanie ogrodów | Wizualizacje | Inwentaryzacje | Białystok i okolice | Zdalnie cała Polska
      <br></br> tel. <a href="tel:+48796636385">796 636 385</a> | e-mail: <a href="mailto:kontakt@hami-landscape.pl">kontakt@hami-landscape.pl</a></h4>
    <h4>Realizacja <a href="https://cloverit.pl" target="_blank" className='cloverit'>CloverIT</a> &copy; 2023  </h4> </div>
  );
}

export default Footer;