import XRegExp from "xregexp";
import * as yup from "yup";

const nameRegex = XRegExp('^(?:[-\' ]?[\\p{L}]+)*$');
const emailRegex = XRegExp('^(?=.{1,64}@)[\\p{L}0-9_-]+(\\.[\\p{L}0-9_-]+)*@[^-][\\p{L}0-9-]+(\\.[\\p{L}0-9-]+)*(\\.[\\p{L}]{2,})$');
const telefonRegex = XRegExp('^(?:\\+48)?(?:[0-9] ?){9}$');


export const contactSchema = yup.object().shape({
    name: yup.string().matches(nameRegex, "Nieprawidłowe imię i nazwisko").required("Pole wymagane"),
    phone: yup.string().matches(telefonRegex, "Nieprawidłowy numer telefonu"),
    email: yup.string().matches(emailRegex, "Nieprawidłowy mail").required("Pole wymagane"),
    pow: yup.string().optional(),
    lok: yup.string().optional(),
    uw: yup.string().optional(),
    model: yup.string().optional(),
    czego: yup.string().optional(),
});