import React, { useState } from 'react';
import './Ogrod_wsrod_sosen.css';
import AliceCarousel from 'react-alice-carousel';
import FsLightbox from "fslightbox-react";

function Nowoczesny() {
      const [thumbIndex, setThumbIndex] = useState(0);
      const responsive = {
            0: {
                  items: 1,
                  itemsFit: 'contain'
            }
      };
      const items = [
            <img src="/4/RZUT.webp" className='carousel-img' alt='carousel' />
      ]
      const gallerySources = [
            "/4/WIZ_1.webp",
            "/4/WIZ_2.webp",
            "/4/WIZ_3.webp",
            "/4/WIZ_4.webp",
            "/4/WIZ_5.webp",
            "/4/WIZ_6.webp",
            "/4/WIZ_7.webp",
            "/4/WIZ_8.webp",
            "/4/WIZ_9.webp",
            "/4/WIZ_10.webp",
            "/4/WIZ_11.webp",
            "/4/WIZ_12.webp",
            "/4/WIZ_13.webp",
            "/4/WIZ_14.webp",
            "/4/WIZ_15.webp",
            "/4/WIZ_16.webp",
            "/4/WIZ_17.webp"
      ]
      const [lightboxController, setLightboxController] = useState({
            toggler: false,
            slide: 1
      });

      const slideNext = () => {
            if (thumbIndex < items.length - 1) {
                  setThumbIndex(thumbIndex + 1);
            } else {
                  setThumbIndex(0);
            }
      };

      const slidePrev = () => {
            if (thumbIndex > 0) {
                  setThumbIndex(thumbIndex - 1);
            } else {
                  setThumbIndex(items.length - 1);
            }
      };

      function openLightboxOnSlide(number) {
            setLightboxController({
                  toggler: !lightboxController.toggler,
                  slide: number
            });
      }
      return (
            <div className='Ogrod_wsrod_sosen-container'>
                  <div className='projekt-upper'>
                        <div className='Ogrod_wsrod_sosen-text'>
                              <h1 id="Ogrod_wsrod_sosen-text">OGRÓD Z WIŚNIAMI</h1>
                              <br />
                              <h5 id="Ogrod_wsrod_sosen-text">
                                    <span class="material-symbols-outlined">
                                          location_on
                                    </span>
                                    Podlaskie
                              </h5>
                              <h5 id="Ogrod_wsrod_sosen-text">
                                    <span class="material-symbols-outlined">
                                          crop
                                    </span>
                                    550m
                                    <sup>2</sup>
                              </h5>
                              <br />
                              <h3 id="Ogrod_wsrod_sosen-text">
                                    GŁÓWNE WYTYCZNE PROJEKTOWE
                              </h3>
                              <h4 id="Ogrod_wsrod_sosen-text">
                                    Nie zawsze mam do czynienia z czystą kartką w postaci działki z świeżo postawionym domem. Tak też
                                    było w tym przypadku – projekt został oparty o istniejące nasadzenia, taras, plac zabaw oraz wędzarkę.
                                    Taras powiększono oraz zmieniono jego wykończenie, obudowano go donicami z palisad oraz odsłonięto
                                    od wiatru drewnianymi autorskimi konstrukcjami. Najwięcej zadziało się w kwestii roślin – tutaj dodano
                                    dużo kolorowych bylin, krzewów oraz szumiących traw. Inwestorzy upodobali sobie kilka egzemplarzy
                                    wiśni ozdobnych oraz katalp. Cały ogród wystrefowano oraz zapewniono komunikację. Te wszystkie
                                    rzeczy pozwolą inwestorom cieszyć się z tej przestrzeni przez długie lata.
                              </h4>
                        </div>
                        <div className='Ogrod_wsrod_sosen-img'>
                              <AliceCarousel
                                    activeIndex={thumbIndex} responsive={responsive} items={items}
                                    animationDuration={800}
                                    autoPlay={false} autoPlayInterval={4000} autoPlayStrategy={'all'} infinite
                                    disableButtonsControls disableDotsControls
                              />
                              {items.length > 1 ? <div className='slide-buttons'>
                                    <div onClick={slidePrev} title="Previous slide" class="">
                                          <div class="fslightbox-flex-centered slide-button">
                                                <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path class="fslightbox-svg-path" d="M18.271,9.212H3.615l4.184-4.184c0.306-0.306,0.306-0.801,0-1.107c-0.306-0.306-0.801-0.306-1.107,0L1.21,9.403C1.194,9.417,1.174,9.421,1.158,9.437c-0.181,0.181-0.242,0.425-0.209,0.66c0.005,0.038,0.012,0.071,0.022,0.109c0.028,0.098,0.075,0.188,0.142,0.271c0.021,0.026,0.021,0.061,0.045,0.085c0.015,0.016,0.034,0.02,0.05,0.033l5.484,5.483c0.306,0.307,0.801,0.307,1.107,0c0.306-0.305,0.306-0.801,0-1.105l-4.184-4.185h14.656c0.436,0,0.788-0.353,0.788-0.788S18.707,9.212,18.271,9.212z">
                                                      </path>
                                                </svg>
                                          </div>
                                    </div>
                                    {thumbIndex + 1}/{items.length}
                                    <div onClick={slideNext} title="Next slide" class="">
                                          <div class="fslightbox-flex-centered slide-button">
                                                <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                      <path class="fslightbox-svg-path" d="M1.729,9.212h14.656l-4.184-4.184c-0.307-0.306-0.307-0.801,0-1.107c0.305-0.306,0.801-0.306,1.106,0l5.481,5.482c0.018,0.014,0.037,0.019,0.053,0.034c0.181,0.181,0.242,0.425,0.209,0.66c-0.004,0.038-0.012,0.071-0.021,0.109c-0.028,0.098-0.075,0.188-0.143,0.271c-0.021,0.026-0.021,0.061-0.045,0.085c-0.015,0.016-0.034,0.02-0.051,0.033l-5.483,5.483c-0.306,0.307-0.802,0.307-1.106,0c-0.307-0.305-0.307-0.801,0-1.105l4.184-4.185H1.729c-0.436,0-0.788-0.353-0.788-0.788S1.293,9.212,1.729,9.212z">
                                                      </path>
                                                </svg>
                                          </div>
                                    </div>
                              </div> : <></>}
                        </div>
                  </div>



                  <div className='projekty-gallery'>
                        {gallerySources.map(pic =>
                              <div className='projekty-gallery-img'>
                                    <img src={pic} onClick={() => openLightboxOnSlide(gallerySources.indexOf(pic) + 1)} alt='gallery' ></img>
                              </div>
                        )}
                  </div>

                  <FsLightbox
                        toggler={lightboxController.toggler}
                        slide={lightboxController.slide}
                        sources={gallerySources}
                        type="image"
                  />

            </div>
      );
}

export default Nowoczesny;