import React from 'react';
import '../App.css';
import './Portfolio.css';
import {Link} from 'react-router-dom';

function Portfolio() {
  return (
    <div className='Portfolio-container'>
    <div className='gallery'><Link to="/ogrod-z-wisniami">
          <img src="/4/WIZ_3.webp"></img>
          <div class="desc"><h5>OGRÓD Z WIŚNIAMI </h5>
             <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
              location_on </span >  Podlaskie</h5>
             <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
              crop
              </span> 550m <sup>2</sup></h5>
        </div></Link>
      </div>

        <div className='gallery'><Link to="/prace-wczesniejsze">
              <img src="/3/1.webp"></img>
              <div class="desc"><h5>PRACE WCZEŚNIEJSZE </h5>
              <br />
              <br />
            </div></Link>
          </div>

<div className='gallery'><Link to="/ogrod-wsrod-sosen"> 
              <img src="/W_1_WEBP.webp"></img>
            <div class="desc"><h5>OGRÓD WŚRÓD SOSEN </h5>
             <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
              location_on </span >  Warmińsko - Mazurskie</h5>
             <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
              crop
              </span> 850m <sup>2</sup></h5>
            </div></Link>
        </div>

    <div className='gallery'><Link to="/ogrod-nowoczesny">
          <img src="/1/1.webp"></img>
          <div class="desc"><h5>OGRÓD NOWOCZESNY </h5>
          <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
          location_on </span >  Podlaskie</h5>
          <h5><span class="material-symbols-outlined" style={{fontWeight:"100", fontSize: "1.1rem"}}>
          crop
          </span>  1600m <sup>2</sup> </h5>
        </div></Link>
      </div>
</div>
  );
}

export default Portfolio;