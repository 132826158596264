import React, { Component } from "react";
import '../App.css';
import './Contact.css';
import Swal from 'sweetalert2'
import { Form, Formik } from "formik";
import CustomInput from '../forms/CustomInput';
import { contactSchema } from '../forms'
import CustomTextArea from "../forms/CustomTextArea";

class Contact extends Component {

sendFeedback = (variables) => {
  let templateId = 'template_y4wpv29';
  window.emailjs.send(
    'service_bipzy46', templateId,
    variables
    ).then(res => {
      console.log("succes") ;
                Swal.fire({
                    title: 'Wiadomość została wysłana', 
                    text: 'PO OTRZYMANIU FORMULARZA W PRZECIĄGU KILKU DNI OTRZYMASZ WIADOMOŚĆ ZWROTNĄ',
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    confirmButtonColor:"#C0C0C0",
                    icon: 'success'
                }
                ).then(res => {
                
                  setTimeout(() => {
                        window.location.reload();
                      }, 1500); console.log('wyslano');
                      console.log(this.state.subject)
                })
              }  
                    
    )
    .catch(err => {
      console.error('Email Error:', err)
    })
  
}

render(){
  return (
    <div className='Contact-container'>

        <div className='Contact-container-dane'>
          <h2>Michał Hajdowski Architekt Krajobrazu</h2>
          <br></br>
          <h4>Projektowanie ogrodów | Wizualizacje | Inwentaryzacje</h4>
          <br></br>
          <br></br>
          <br></br>
          <div className="contact-icons-line">
          <div className='contact-icons'>
              <img src="mapa_polska.svg" ></img>
              </div>

            <div className='contact-icons-text'>
            <h4 id="contact-icons-text">Białystok i okolice</h4>
            <h4 id="contact-icons-text">Zdalnie cała Polska</h4>
          </div></div>

          <div className="contact-icons-line">
          <div className='contact-icons'>
          <span class="material-symbols-outlined">
                mail
                </span>
                {/* <img src="email.png"></img> */}
                </div>
                <div className='contact-icons-text'>
            <h4 id="contact-icons-text"><a href="mailto:kontakt@hami-landscape.pl">kontakt@hami-landscape.pl</a></h4>
           </div></div>
          
           <div className="contact-icons-line">
          <div className='contact-icons'>
          <span class="material-symbols-outlined">
            phone
            </span> 
            {/* <img src="phone-call.png"></img> */}
            </div>
            <div className='contact-icons-text'>
            <h4 id="contact-icons-text"><a href="tel:+48796636385">796 636 385</a></h4>
            </div></div>
         

        </div>

        

        <div className='Contact-container-form'>
        
        {/* <form class="app-form" onSubmit={this.handleSubmit}>
                <h2>Wypełnij formularz kontaktowy aby otrzymać wycenę</h2> 
                 <br></br>
              <div class="form-line">
                <h4>Imię i Nazwisko</h4>
              <input class="app-form-control" type="text" onChange={this.nameChange} placeholder="IMIĘ NAZWISKO" />
            </div>
            <div class="form-line">
            <h4>E-mail</h4>
              <input class="app-form-control" type="email" onChange={this.emailChange} placeholder="EMAIL"/>
            </div>
            <div class="form-line">
            <h4>Telefon</h4>
              <input class="app-form-control" type="tel" onChange={this.phoneChange} placeholder="TELEFON"/>
            </div>
            <div class="form-line">
            <h4>Powierzchnia działki</h4>
              <input class="app-form-control" type="text" onChange={this.powChange} placeholder="POWIERZCHNIA DZIAŁKI" />
            </div>
            <div class="form-line">
            <h4>Lokalizacja (Miejscowość, numer działki)</h4>
              <input class="app-form-control" type="text" onChange={this.lokChange} placeholder="LOKALIZACJA" />
            </div>
            <div class="form-line">
            <h4>Uwarunkowanie terenu (teren płaski, skarpa, rzeka, oczko)</h4>
              <input class="app-form-control" type="text" onChange={this.uwChange} placeholder="UWARUNKOWANIE TERENU" />
            </div>
            <div class="form-line">
            <h4>Model domu / link do strony z projektami (opcjonalnie)</h4>
              <input class="app-form-control" type="text" onChange={this.modelChange} placeholder="MODEL DOMU /LINK DO PROEJKTU" />
            </div>
            <div class="form-line">
            <h4>Czego potrzebujesz w projekcie?</h4>
              <textarea class="app-form-control" type="text" onChange={this.czegoChange} rows="8" cols="50" placeholder="CZEGO POTRZEBUJESZ W PROJEKCIE?"/>
            </div>
            <div class="form-line">
              <button class="app-form-button" type="submit"  checked={ this.state.checkedSubmit }  onChange={ this.handleSubmit }>WYŚLIJ</button>
            </div>

              </form> */}
          
          <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                pow: '',
                lok: '',
                uw: '',
                model: '',
                czego: ''
            }}
            validationSchema={contactSchema}
            onSubmit={async (values, { setSubmitting }) => {
                this.sendFeedback(values);
                setSubmitting(false);
            }}
            >
            {({ isSubmitting }) => (
                <div className='app-form'>
                    <Form>
                      <h2>Wypełnij formularz kontaktowy aby otrzymać wycenę</h2> 
                      <br />
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="IMIĘ NAZWISKO"
                            label="Imię i Nazwisko"
                            name="name"
                            type="text" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="E-MAIL"
                            label="E-mail"
                            name="email"
                            type="email" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="TELEFON"
                            label="Telefon"
                            name="phone"
                            type="tel" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="POWIERZCHNIA DZIAŁKI"
                            label="Powierzchnia działki"
                            name="pow"
                            type="text" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="LOKALIZACJA"
                            label="Lokalizacja (Miejscowość, numer działki)"
                            name="lok"
                            type="text" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="UWARUNKOWANIE TERENU"
                            label="Uwarunkowanie terenu (teren płaski, skarpa, rzeka, oczko)"
                            name="uw"
                            type="text" />
                      </div>
                      <div class="form-line">
                        <CustomInput
                            className='app-form-control'
                            placeholder="MODEL DOMU / LINK DO PROJEKTU"
                            label="Model domu / link do strony z projektami (opcjonalnie)"
                            name="model"
                            type="text" />
                      </div>
                      <div class="form-line">
                        <CustomTextArea
                            className='app-form-control'
                            placeholder="CZEGO POTRZEBUJESZ W PROJEKCIE?"
                            label="Czego potrzebujesz w projekcie?"
                            rows="8"
                            cols="50"
                            name="czego"
                            type="text" />
                      </div>
                      <div className="form-line">
                          <button className="app-form-button" disabled={isSubmitting} type="submit">WYŚLIJ</button>
                      </div>
                    </Form>
                </div>  
            )}
          </Formik>
        </div>
</div>
  );
}
}

export default Contact;