import React from 'react';
import '../App.css';
import './Hero.css';
import {Link} from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';

function Hero() {

  const items = [
    <img src="/placeholder_1.webp" />,
    <img src="/placeholder_2.webp" />
  ]
  const responsive = {
    0: {
          items: 1,
          itemsFit: 'contain'
    }
  };
  return (
    <div className='Hero-container'>
        <div className='hero-image'>
            <AliceCarousel
              responsive={responsive} items={items}
              animationDuration={800}
              autoPlay autoPlayInterval={4000} autoPlayStrategy={'none'} infinite
              disableButtonsControls disableDotsControls />
        </div>
        <div className='hero-projects-text'><h1>Najnowsze projekty</h1>
          {/* <h4>jakis tekst mniejszy o ostatnich projekttach itp.</h4> */}
          </div>
        <div className='hero-projects'>
          <div className='hero-projects-img'>
                  <Link to="/ogrod-z-wisniami" className='hero-projects-href'>
            <img src="/4/WIZ_3.webp" className='projects-img' />
            <div class="overlay"><div class="text">OGRÓD Z WIŚNIAMI </div>
                </div></Link></div>
          <div className='hero-projects-img'>
                  <Link to="/prace-wczesniejsze" className='hero-projects-href'>
            <img src="/3/1.webp" className='projects-img' />
            <div class="overlay"><div class="text">PRACE WCZEŚNIEJSZE </div>
                </div></Link></div>
        
          <div className='hero-projects-img'>
                  <Link to="/ogrod-wsrod-sosen" className='hero-projects-href'>
            <img src="/W_1_WEBP.webp" className='projects-img' />
            <div class="overlay"><div class="text">OGRÓD WŚRÓD SOSEN </div>
                </div></Link>
            </div>

          <div className='hero-projects-img'>
                  <Link to="/ogrod-nowoczesny" className='hero-projects-href'>
            <img src="/1/1.webp" className='projects-img' />
            <div class="overlay"><div class="text">OGRÓD NOWOCZESNY </div>
                </div></Link></div>
        </div>
</div>
  );
}

export default Hero;