import React from 'react';
import '../App.css';
import './Project.css';
import Fade from 'react-reveal/Fade';

function Project() {
  return (
    <div className='Project-container'>
<Fade left>
      <div className='Project-container-text'>
          <h2>DLACZEGO WARTO SKORZYSTAĆ Z USŁUG PROJEKTOWYCH</h2>
          <br></br>
          <img src='logo_projektowanie.svg' />
          {/* <span class="material-symbols-outlined" style={{fontWeight:"300", textAlign:"center",fontSize:"40px"}}>
              help
              </span> */}
              {/* <span class="material-symbols-outlined" style={{fontWeight:"100", textAlign:"center",fontSize:"40px"}}>
                  psychiatry
                  </span> */}
              <br></br>
              <br></br>
              <h4>W trakcie każdej budowy lub odbioru gotowego domu rodzi się pytanie – co dalej? Mam sporą bądź małą przestrzeń i co z nią zrobić? Jakiej wielkości taras wygospodarować i co na nim umieścić? Jakie rośliny dadzą mi efekt osłony, jakie poradzą sobie w obecnych warunkach?
                      Może altana w rogu działki a może palenisko w gruncie blisko tarasu? W jakiej formie to wszystko ma się ukształtować? Z pomocą przychodzi projekt – jasne wytyczne dla Ciebie i/lub podwykonawcy. Przy współpracy ze mną otrzymasz kompletne opracowanie w odpowiedniej dla Ciebie formie – elektronicznej i/lub papierowej.
                      </h4>

                                    <br></br>
                                   <br></br>
     
          <div className='Project-container-img'>
              <img src="/W_4_WEBP.webp"></img>
            </div>
      </div>
      </Fade>

      <Fade right>
      <div className='Project-container-text'>
      <h2>CO OTRZYMASZ JEŚLI ZDECYDUJESZ SIĘ NA PROJEKT OGRODU</h2>
                <br></br>
                        <span class="material-symbols-outlined" style={{fontWeight:"400", textAlign:"center",fontSize:"40px"}}>
                  psychiatry
                  </span>
                  <br></br>
                  <br></br>
                <h4>Zanim zdecydujesz się na współpracę, zobacz co otrzymasz w jej ramach.</h4>
                <br></br>
                <h4><ul style={{padding:"3%"}}>
                  <li>Wstępna kolorowa koncepcja działki w postaci rzutu, który zawiera objaśnienia, widoki robocze i wstępny dobór roślinności</li>
                  <li>Wizualizacje</li>
                </ul></h4>

                <h4>W późniejszym etapie po zaakceptowaniu koncepcji z uwagami i po poprawkach powstaje dokumentacja projektowa zawierająca:</h4>
                <br></br>
                <h4><ul style={{padding:"3%"}}>
                  <li>Wymiarowanie wszystkich projektowanych elementów – nawierzchni, nasadzeń, projektowanych elementów małej architektury takich jak ławki, donice, pergole.</li>
                  <li>Projekt nasadzeń z doborem roślin</li>
                  <li>Bilans materiałów użytych w projekcie</li>
                  <li>Dobór wyposażenia czyli mebli, donic i innych elementów</li>
                  <li>Schemat oświetlenia z doborem opraw świetlnych, lamp, itp.</li>
                </ul></h4>
     </div>
     </Fade>

     <div className='Project-container-bottom'>
            <h3 id="Project-container-bottom">Nie możesz sobie pozwolić na kompleksowy projekt ale masz luźne pomysły? Nie ma problemu! Oferuję również usługi konsultacji projektowych. Jak to działa? Umawiamy się zdalnie poprzez wideorozmowę lub w wybranym miejscu osobiście i omawiamy wszystkie zebrane przez Ciebie inspiracje i pomysły na ogród. Zwykle 2-3 godziny wystarczą aby poruszyć wszystkie tematy i nakreślić co tak naprawdę musisz zrobić w ogrodzie :)</h3>
     </div>


          <Fade left>
            <div className='Project-container-text'>
            <h2>ETAPY WSPÓŁPRACY</h2>
            <br></br>
            <img src='/logo_dlonie.svg' />
            {/* <span class="material-symbols-outlined" style={{fontWeight:"300", textAlign:"center",fontSize:"40px"}} >
              task
              </span> */}
              {/* <span class="material-symbols-outlined" style={{fontWeight:"100", textAlign:"center",fontSize:"40px"}}>
                  psychiatry
                  </span> */}
              <br></br>
              <br></br>
              <img className='etap-wspolpracy' src='/jedynka_symbol.svg' /> 
              <h4> Umowa, ankieta, rozmowa –  wszystko zaczyna się od podpisania umowy i rozmowy o tym co w ogrodzie ma się znaleźć i komu ma służyć. Wtedy też przedstawiam krótką i treściwą ankietę, która pomaga mi zrozumieć w jakim kierunku patrzeć przy projektowaniu. Jeśli zachodzi potrzeba inwentaryzacji działki i istniejących nasadzeń to w miarę możliwości pojawiam się osobiście na miejscu. Większość projektów jest do zrobienia zdalnie z pomocą inwestora.</h4>
              <br></br>
              <img className='etap-wspolpracy' src='/dwojka_symbol.svg' /> 
              <h4> Wstępna koncepcja i wizualizacje –  po około 4 tygodniach (nie jest to regułą co do wszystkich projektów) pojawia się koncepcja wstępna z wizualizacjami, która omawiana jest razem z Tobą. Wskazane elementy są zmieniane i poprawiane.</h4>
              <br></br>
              <img className='etap-wspolpracy' src='/trojka_symbol.svg' /> 
              <h4> Poprawki, zmiany koncepcji, adaptacja – tutaj dużą rolę odgrywa klient i jego decyzyjność co do finalnego kształtu projektu. Najczęściej odbywa się to mailowo/poprzez rozmowy telefoniczne lub wideorozmowy. Zwykle w przeciągu 2 tygodni zamykamy temat koncepcji.</h4>
              <br></br>
              <img className='etap-wspolpracy' src='/czworka_symbol.svg' /> 
              <h4> Dokumentacja projektowa – Finalny etap, zwykle zajmuje od 2-4 tygodni (nie jest to reguła!). Tworzone są wszystkie rysunki potrzebne do wykonania ogrodu i jego składowych.</h4>

          <br></br>
          <br></br>
          <br></br>
          <h2>KOSZTA PROJEKTU I CZAS REALIZACJI</h2>
          <br></br>
          <img src='/logo_kaska.svg' />
          {/* <span class="material-symbols-outlined" style={{fontWeight:"300", textAlign:"center",fontSize:"40px"}}>
            finance_chip
            </span> */}
            {/* <span class="material-symbols-outlined" style={{fontWeight:"100", textAlign:"center",fontSize:"40px"}}>
                  psychiatry
                  </span> */}
            <br></br>
            <br></br>
            <h4>Wycena projektu jest możliwa tylko i wyłącznie po uzyskaniu wszystkich niezbędnych informacji odnośnie działki i składowych zagospodarowania.</h4>
            <br></br>
                <h4>Czas realizacji projektu jest zależy od jego powierzchni oraz ilości elementów, które mają się znaleźć w ogrodzie. Zazwyczaj jest to okres od 2 do 3 miesięcy.</h4>
 </div></Fade>
 <Fade right>
            <div className='Project-container-img-1'>
                          
                          <img src="/W_3_WEBP_1.png"></img>
                        </div></Fade>


          </div>

  );
}

export default Project;