import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import { GiRocketThruster } from "react-icons/gi";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "black" }}>
        <nav className="navbar">
          <div className="navbar-container container">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/LOGO_STRONA6.svg" className='logo'></img>
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  to="/o-mnie"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                   O MNIE
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/portfolio"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  PORTFOLIO
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/projekt"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  PROJEKT
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/kontakt"
                  className={({ isActive }) =>
                    "nav-links" + (isActive ? " activated" : "")
                  }
                  onClick={closeMobileMenu}
                >
                  KONTAKT
                </NavLink>
              </li>     
              
              <li className="nav-item" id="logos">
                
                <a href="https://www.facebook.com/hami.landscape" target="_blank" rel="noreferrer" >
                     <img src="/LOGO_FB.svg" className='social'/>
                </a> 
                
                 <a href='https://www.instagram.com/hami_landscape' target="_blank" rel="noreferrer" >
                    <img src="/LOGO_IG.svg" className='social'/>
               </a> 
               </li>
            </ul>
       
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;