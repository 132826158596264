import React from 'react';
import '../App.css';
import './About.css';

function About() {
  return (
    <div className='About-container'>
      <div className='about-section'>
        <div className='about-img'>
          <img src="/prop_2.webp"></img>
        </div>
        <div className='about-text-container'>
        <div className='about-text'>
          <h1>Michał Hajdowski Architekt Krajobrazu</h1>
          <h4>Jestem Michał i to ja tworzę hami! Odkąd pamiętam zawsze byłem kreatywny – jako dziecko kreowałem scenariusze i scenografie dla moich zabawek, by potem wszystko zrównać z ziemią i zacząć spektakl od nowa. W międzyczasie pojawiła się technologia i duże zamiłowanie do grafiki komputerowej oraz gier – gdzie ramy obrazów wychodzą poza format i gdzie można robić dosłownie wszystko. Lubiłem naturę ale na swój sposób – jako odskocznię od świata wirtualnego i coś idealnego, niepowtarzalnego. Gdy pojawiła się dorosłość i trudne wybory ścieżki rozwoju postawiłem na architekturę krajobrazu. I ta została ze mną do dziś!

</h4>
<span class="material-symbols-outlined" style={{fontWeight:"300", textAlign:"center"}}>
school
</span>

<h4>Ukończyłem 2-stopniowe studia na Politechnice Białostockiej z tytułem magistra inżyniera architekta krajobrazu. W trakcie studiów zawsze dążyłem do tego, aby projekty były solidne i dopracowane ale jednocześnie pokazywały, że mają to magiczne „coś”. Moimi ulubionymi kierunkami w architekturze ogrodowej są styl nowoczesny oraz contemporary. Lubię mieszać w swojej pracy – rzeczy nowe z zastanymi starociami aby ujednolicić całość i stworzyć coś oryginalnego. Interesuje się również zielono-błękitną infrastrukturą miast – ogrodami deszczowymi oraz zielonymi dachami. Z wielkim szacunkiem podchodzę do roślinności w każdym z moich projektów – zawsze uwzględniam każde uwarunkowania i staram się dążyć do tego, aby ogród uzupełniał otaczający krajobraz i funkcjonował według upodobań klienta.</h4>
<span class="material-symbols-outlined" style={{fontWeight:"300"}}>
star_rate
</span>
          <h4>Wyróżnienie w konkursie SPAK-u na najlepszą pracę dyplomową. Temat pracy „Projekt ogrodu na dachu Teatru Dramatycznego im. Aleksandra Węgierki w Białymstoku”</h4>
          <span class="material-symbols-outlined" style={{fontWeight:"300"}}>
star_rate
</span>
          <h4>Główna nagroda w konkursie Prezydenta Miasta Białegostoku na pracę magisterską związaną z problemami przestrzennymi miasta. Temat pracy: „Zielono-błękitna infrastruktura w koncepcji zagospodarowania Parku Antoniuk w Białymstoku”</h4>
          </div>
        </div>
</div>
</div>
  );
}

export default About;