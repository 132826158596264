import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './pages/About';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Project from './pages/Project';
import Ogrod_wsrod_sosen from './pages/projects/Ogrod_wsrod_sosen';
import { Routes, Route} from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import ScrollToTop from "./ScrollToTop";
import Nowoczesny from './pages/projects/Nowoczesny';
import Wcześniejsze from './pages/projects/Wcześniejsze';
import Ogrod_z_wisniami from './pages/projects/Ogrod_z_wisniami';

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop>
     <Navbar sticky="top"/>
     <Routes>
          <Route path='/' element={<Hero/>} />
          <Route path='/o-mnie' element={<About/>} />
          <Route path='/kontakt' element={<Contact/>} />
          <Route path='/portfolio' element={<Portfolio/>} />
          <Route path='/projekt' element={<Project/>} />
          <Route path='/ogrod-nowoczesny' element={<Nowoczesny/>} />
          <Route path='/ogrod-wsrod-sosen' element={<Ogrod_wsrod_sosen/>} />
          <Route path='/ogrod-z-wisniami' element={<Ogrod_z_wisniami/>} />
          <Route path='/prace-wczesniejsze' element={<Wcześniejsze/>} />
     </Routes>
     <Footer/>
     </ScrollToTop>
     </BrowserRouter>
    </>
  );
}

export default App;